import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/presse-bpong-pdf.jpg'
import presseImage1 from '@images/presse/presse-bpong.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12`

const PressePage = () => {
  const pressePdf = '/download/ERDINGER_Brauhaus_wird_Partner_der_BPong-Bundesliga_Informationen.pdf';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                It's a hit - ERDINGER Brauhaus wird Partner der BPong-Bundesliga
              </Subtitle>
              <ContentText>
                <p>
                  Erding, Juli 2023<br />
                  Beerpong boomt wie kaum eine andere Sportart! Sport? Richtig! Das (ehemalige) Partyspiel ist der 
                  rising star in der Welt des Sports. Bereits über 90 Teams aus Deutschland, Österreich, Luxemburg 
                  und der Schweiz jagen in zehn Ligen nach Hits, Perfects und heißen Matches. Am 31. Juli beginnt 
                  die siebte Saison der BPong-Bundesliga - mit ERDINGER Brauhaus als neuem Titelsponsor.
                </p>
                <p>
                  Präzision, Power, Professionalität. Die Faszination BPong wächst weiter. „Seit der Gründung der 
                  BPong-Bundesliga hat Beerpong eine beeindruckende Entwicklung vom reinen Partyspiel zu einer 
                  angesagten Trendsportart mit professionellen Strukturen durchlaufen. Gemeinsam mit BPong werden wir 
                  dem Beerpong-Boom noch einmal einen richtigen Impuls geben“, ist sich Dr. Stefan Kreisz, 
                  Vorsitzender der Geschäftsführung bei der Privatbrauerei ERDINGER Weißbräu, sicher und erklärt: 
                  „Beerpong bietet alles, was uns am Sport fasziniert: Spannung, Leidenschaft, Können und großartige 
                  Erlebnisse in der Gemeinschaft. Passt also perfekt zu ERDINGER Brauhaus. Mit unserer Partnerschaft 
                  feiern wir die wahre Liebe zum Bier - in sportlicher und genussvoller Hinsicht.“ Der Mit-Gründer der 
                  BPong-Bundesliga, Nico Beckmann, ist ebenfalls begeistert. „Die Partnerschaft mit ERDINGER Brauhaus 
                  ist für uns ein Meilenstein“, betont er: „Gemeinsam können wir die Sportart weiter vorantreiben. 
                  Mit der Unterstützung von ERDINGER Brauhaus werden wir die BPong-Bundesliga auf ein neues Level 
                  heben und noch mehr Menschen für diesen faszinierenden Sport begeistern.“<br/>
                  Mit Beginn der neuen Saison performen die Besten der Besten zum siebten Mal in der BPong Bundesliga 
                  - ab sofort unterstützt von ERDINGER Brauhaus als Titelsponsor. Natürlich stellt ERDINGER daher das 
                  offizielle Spielbier. Zudem ist ERDINGER Brauhaus auf allen Kanälen der BPong-Bundesliga 
                  eingebunden: Die Top-Spiele im Live-Stream auf Twitch, nach jedem Spieltag eine neue Folge 
                  „Last Cup - Der Podcast zur BPong-Bundesliga“ und natürlich fortlaufend auf den Social Media 
                  Accounts. Spieler und Fans dürfen sich schon jetzt auf erstklassiges Entertainment live und online 
                  freuen.
                </p>
                <p>
                  Der BPong-Bundesliga folgen:<br />
                  Homepages: <a href="https://www.erdinger-brauhaus.de/" target="_blank">www.erdinger-brauhaus.de/</a>
                  <span> & </span> <a href="https://bpbl.de/" target="_blank">bpbl.de/</a><br />
                  Twitch: <a href="https://www.twitch.tv/bpongbundesliga" target="_blank">bpongbundesliga</a><br />
                  TikTok: <a href="https://www.tiktok.com/@bpongbundesliga" target="_blank">bpongbundesliga</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                  <span> & </span> <a href="https://www.instagram.com/bpongbundesliga/" target="_blank">@bpongbundesliga</a>
                </p>
              </ContentText>

              <p tw="text-center mt-8">
                <strong>Pressemeldung (PDF)</strong>
              </p>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={pressePdf} 
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="It's a hit - ERDINGER Brauhaus wird Partner der BPong-Bundesliga" />
                  </a>
                  <ImageSource>
                    It's a hit - ERDINGER Brauhaus wird Partner der BPong-Bundesliga
                  </ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentRow>
                <ContentRowImage>
                  <img src={presseImage1} alt="ERDINGER Brauhaus und die BPong-Bundesliga stoßen auf die neue Partnerschaft an." />
                </ContentRowImage>
              </ContentRow>
              <ImageSource>
                ERDINGER Brauhaus und die BPong-Bundesliga stoßen auf die neue Partnerschaft an.<br />
                v.l.n.r.:<br />
                Wolfgang Kuffner, Gesamtleitung Marketing ERDINGER Weißbräu<br />
                Felix Pohl, Marketing und Events BPong-Bundesliga<br />
                Stefan Huckemann, Geschäftsführer Finanzen / Controlling / IT und Organisation ERDINGER Weißbräu<br />
                Steffen Meier, Verkaufsleiter ERDINGER Weißbräu<br />
                Nikolai Becker, Web und Liga Management BPong-Bundesliga<br />
                Dr. Stefan Kreisz, Vorsitzender der Geschäftsführung ERDINGER Weißbräu<br />
                David Homm (verdeckt), Design und Content BPong-Bundesliga<br />
                Nico Beckmann, Geschäftsführer BPong-Bundesliga
              </ImageSource>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
